<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="tariffTable"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :tbody-tr-class="rowClass"
            :items="tariffPerformerBase"
            :fields="fields"
            v-hotkey="{
              insert: createTariffPerformer,
              enter: editForm,
              'alt + f5': copyTariffPerformer,
            }"
            @row-clicked="clicked"
            @row-dblclicked="editForm"
            @row-contextmenu="handleContextMenuEvent"
            head-row-variant="primary"
          >
            <template #cell(blocked)="data">
              <span>
                {{ data.item.blocked === 1 ? "Да" : "Не" }}
              </span>
            </template>
            <template #cell(sentings)="data">
              <span class="reference-field" @dblclick="getSentings(data)">
                (***)
              </span>
            </template>
            <template #cell(history_version)="data">
              <span class="reference-field"> (***) </span>
            </template>
            <template #cell(allowance)="data">
              <span class="reference-field"> (***) </span>
            </template>
            <template #cell(district_surcharge)="data">
              <span class="reference-field"> (***) </span>
            </template>
            <template #cell(legal_entities)="data">
              <span>
                {{ data.item.legal_entities === 1 ? "Да" : "Не" }}
              </span>
            </template>
            <template #cell(type_tariff_performer)="data">
              {{ data.item.type_tariff_performer_id }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click="createTariffPerformer"
              >
                <feather-icon icon="PlusIcon" size="16" />
                <span class="ml-75">Добавить</span>
                <span class="text_INS">Ins</span>
              </b-link>
            </li>
            <li>
              <b-link class="d-flex align-items-center" @click="editForm">
                <feather-icon icon="EditIcon" size="16" />
                <span class="ml-75">Изменить</span>
                <span class="text_enter">Enter</span>
              </b-link>
            </li>
            <li>
              <b-link
                class="d-flex align-items-center"
                @click="copyTariffPerformer"
              >
                <feather-icon icon="CalendarIcon" size="16" />
                <span class="ml-75">Копировать</span>
                <span class="text_ALT">Alt+F5</span>
              </b-link>
            </li>
            <li>
              <b-link
                class="d-flex align-items-center"
                @click.prevent="getActiveTariff"
              >
                <feather-icon icon="CalendarIcon" size="16" />
                <span class="ml-75">Обновить активную версию</span>
              </b-link>
            </li>
            <hr />
            <li>
              <b-link class="d-flex align-items-center" @click="autoUpdate">
                <img
                  :src="$store.state.updates.update ? iconsCheck : iconsSquare"
                />
                <span class="ml-1">Автообновление</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueContext,
  },
  name: "tariffPerformer",
  props: ["tariffPerformerBase"],
  data() {
    return {
      id: null,
      fields: [
        { key: "id", label: "ID", sortable: true, thStyle: { width: "50px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "sort_number",
          label: "Порядок",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "name",
          label: "Наименование",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "blocked",
          label: "Блокирован",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "type_tariff_performer",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "sentings",
          label: "Настройка",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "car_class",
          label: "Классы авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "legal_entities",
          label: "Для юр.лиц",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "basis",
          label: "Основной",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "dop_info",
          label: "Доп.инфо",
          sortable: true,
          thStyle: { width: "100px" },
        },
      ],
      iconsSquare: require("@/assets/images/icons/square.svg"),
      iconsCheck: require("@/assets/images/icons/checkSquareIcon.svg"),
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
    },
    rowClass(item) {
      if (item.color === "white") return "table-white";
      else if (item.color === "green") return "table-success";
      else if (item.color === "red") return "table-danger";
    },
    getSentings(data) {
      this.$router.push({
        name: "tariff/settings",
        query: { allowances_id: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Настройки тарифа " + data.item.id,
        `/tariff/settings`,
        data.item.id,
      ]);
    },
    createTariffPerformer() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    editForm() {
      let id = this.id;
      this.$emit("editForm", id);
    },
    copyTariffPerformer() {
      let id = this.id;
      this.$emit("copyTariffPerformer", id);
    },
    getActiveTariff() {
      let id = this.id;
      this.$http
        .post(`tariff-performers/base/${id}/update-active`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    autoUpdate() {
      this.$store.state.updates.update = !this.$store.state.updates.update;
      this.$emit("timerAfto");
    },
    clicked(item, index, event) {
      this.id = item.id;
      this.$store.state.filterPlus.tableIndex = index;

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
.text_INS {
  margin-left: 90px;
}
.text_enter {
  margin-left: 75px;
}
.text_ALT {
  margin-left: 54px;
}
.text-Update {
  margin-left: 27px;
}
</style>
